import { Result, NoneType, None, SourceType } from './types'
import * as constants from './constants'

// TODO(alex): Move identifierIsValid tests from functions tests out to a common
// testing location
export function identifierIsValid(identifier: string): boolean {
  if (identifier === undefined) {
    return false
  }

  const validIdentifierRegexString = '[^0-9a-zA-Z-_]'
  const validIdentifier = new RegExp(validIdentifierRegexString)

  return !validIdentifier.test(identifier)
}

export function sourceUrlIsValid(
  sourceType: SourceType,
  sourceUrl: string,
): Result<NoneType> {
  switch (sourceType) {
    case SourceType.dropbox: {
      return sourceUrl === constants.DEFAULT_DROPBOX_SOURCE_URL
        ? None
        : new Error('Source URL was not a valid Dropbox "virtual" URL')
    }
    case SourceType.private_git_ssh:
    // FALLTHROUGH_INTENDED
    case SourceType.public_git_http: {
      return gitRepoUrlIsValid(sourceUrl)
    }
  }

  return None
}

export const gitRepoUrlIsValid: (gitRepoUrl: string) => Result<NoneType> = (
  gitRepoUrl,
) => {
  const validPrefixRegexString = '(https?://)|(ssh://)'
  const validPrefix = new RegExp(validPrefixRegexString)
  if (!validPrefix.test(gitRepoUrl)) {
    return new Error(
      'Git URI should be start with "http://", "https://" or "ssh://"',
    )
  }

  const validSuffix = '.git'
  if (!gitRepoUrl.endsWith(validSuffix)) {
    return new Error('Git URI should end with .git')
  }
  return None
}

// TODO(alex): Write tests
const VALID_DOMAIN_CHARACTER = 'a-z\\d'
const VALID_DOMAIN_PART = `[${VALID_DOMAIN_CHARACTER}]([${VALID_DOMAIN_CHARACTER}\\-]{0,61}[${VALID_DOMAIN_CHARACTER}])?`
const VALID_DOMAIN_PATTERN = `^${VALID_DOMAIN_PART}(\\.${VALID_DOMAIN_PART})*$`
const VALID_DOMAIN_REGEX = new RegExp(VALID_DOMAIN_PATTERN, 'i')
export function domainIsValid(domain: string): boolean {
  if (domain === undefined) {
    return false
  }

  return VALID_DOMAIN_REGEX.test(domain)
}

const MATCH_LEADING_PERIODS = new RegExp('^\\.+')
const MATCH_UNSUPPORTED_CHARACTERS = new RegExp('[*\\[\\]?]+', 'g')
export function sanitizePath(path: string): string {
  return path
    .trim()
    .split('/')
    .map((component) => component.replace(MATCH_LEADING_PERIODS, ''))
    .map((component) => component.replace(MATCH_UNSUPPORTED_CHARACTERS, ''))
    .filter((component) => -1 == ['..', '', '.'].indexOf(component))
    .join('/')
}
