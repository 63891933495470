import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'

import firebase from 'firebase/app'
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth'

import { WindowLocation } from '@reach/router'

import { navigate } from 'gatsby'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import Loadable from '@loadable/component'

import { None, Option, SiteConfig } from '../utils/types'
import { validateEmail } from '../utils/helpers'

/* eslint-disable @typescript-eslint/no-var-requires */
const { siteMetadata }: SiteConfig =
  require('../../gatsby-config.ts') as SiteConfig
/* eslint-enable @typescript-eslint/no-var-requires */

function emailDomainIsAcceptedForSignIn(address: string): Option<Error> {
  // No restrictions on production.
  if (siteMetadata.config.environment === 'production') {
    return None
  }

  // Local and staging only support email login from @hostburro.com addresses.
  const [recipient, domain, ...rest] = address.split('@')
  void recipient
  void rest
  if (domain !== 'hostburro.com') {
    return Error('Address is not accepted in this environment.')
  }

  return None
}

const sendFirebaseAuthSignInEmail = async (
  firebaseApp: firebase.FirebaseApp,
  address: string,
  redirectTo: string,
) => {
  const actionCodeSettings = {
    url: redirectTo,
    handleCodeInApp: true,
  }
  await sendSignInLinkToEmail(getAuth(firebaseApp), address, actionCodeSettings)

  // TODO(alex): Should we be saving this in local storage?  Is there any way
  // this could leak credentials and allow an attacker to log in on behalf of a
  // user?
  window.localStorage.setItem('emailForSignIn', address)
}

type SignInProps = {
  location: WindowLocation
  firebase: firebase.FirebaseApp
}

const SignIn: FunctionComponent<SignInProps> = ({
  location,
  firebase: firebaseApp,
}) => {
  React.useEffect(() => {
    const asyncEffect = async () => {
      // Check and see if we're signing in from a link
      if (isSignInWithEmailLink(getAuth(firebaseApp), location.href)) {
        const address = window.localStorage.getItem('emailForSignIn')
        if (!address) {
          // TODO(alex): Handle the case where the patron tries to sign in with a
          // different browser than the one they sent the link from.

          // address = window.prompt('Please provide your email for confirmation');
          console.error(
            'This browser was not the one used to send the sign in email',
          )
          return
        }

        await signInWithEmailLink(getAuth(firebaseApp), address, location.href)

        window.localStorage.removeItem('emailForSignIn')
        await navigate('/admin')
      }
    }

    // Call effect once auth is resolved.
    const unsubscribe = getAuth(firebaseApp).onAuthStateChanged(() => {
      void asyncEffect()
    })

    return () => {
      unsubscribe()
    }
  })

  const redirectTarget = (location.state as { redirectSource?: string })
    ?.redirectSource

  // Local development w/ Firebase Auth is way simpler with the Google auth
  // provider than having to go through email every time.
  let maybeGoogleLoginButton = <></>
  if (siteMetadata?.config?.environment == 'local') {
    const LoadableFirebaseAuth = Loadable(
      () => import('../components/firebase-auth'),
    )
    maybeGoogleLoginButton = (
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={12}>
          <LoadableFirebaseAuth
            firebase={firebaseApp}
            redirectTo={redirectTarget || '/'}
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Helmet
        title="Sign in to HostBurro"
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}
      />
      <h1>Sign in</h1>
      {maybeGoogleLoginButton}
      <EmailSignInForm
        directSignInTo={siteMetadata.config.signInDeepLink}
        firebaseApp={firebaseApp}
      />
    </>
  )
}

type EmailSignInFormProps = {
  directSignInTo: string
  firebaseApp: firebase.FirebaseApp
}

const EmailSignInForm: FunctionComponent<EmailSignInFormProps> = ({
  directSignInTo,
  firebaseApp,
}) => {
  const [currentlySendingSignInEmail, setCurrentlySendingSignInEmail] =
    React.useState(false)
  const [signInEmailSent, setSignInEmailSent] = React.useState(false)
  // NOTE(alex): Email being valid doesn't mean that its _sanitary_.
  // Sanitization must still occur for serialization of address.
  const [validEmail, setValidEmail] = React.useState<string | undefined>(
    undefined,
  )
  const [validationError, setValidationError] = React.useState('')
  return (
    <form
      onSubmit={(e) => {
        const submitter = async () => {
          if (validEmail === undefined) {
            console.error('Submission attempted without a valid email address')
            return
          }

          setCurrentlySendingSignInEmail(true)

          await sendFirebaseAuthSignInEmail(
            firebaseApp,
            validEmail,
            directSignInTo,
          )

          // TODO(alex): Inform the patron to go check their email and
          // sign-in / sign-up.

          setSignInEmailSent(true)
          setCurrentlySendingSignInEmail(false)
        }

        void submitter()
        e.preventDefault()
        return false
      }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <TextField
            required
            id="login-email"
            label="Email address"
            fullWidth
            variant="outlined"
            disabled={currentlySendingSignInEmail || signInEmailSent}
            error={validationError !== ''}
            helperText={validationError}
            onChange={(event) => {
              const rawAddress = event?.target?.value.trim()
              if (rawAddress === '') {
                setValidEmail(undefined)
                setValidationError('Email address is required')
                return
              }

              const validationResult = validateEmail(rawAddress)
              if (validationResult !== None) {
                setValidEmail(undefined)
                setValidationError(validationResult.message)
                return
              }

              const domainAllowedResult =
                emailDomainIsAcceptedForSignIn(rawAddress)
              if (domainAllowedResult !== None) {
                setValidEmail(undefined)
                setValidationError(domainAllowedResult.message)
                return
              }

              setValidEmail(rawAddress)
              setValidationError('')
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          {currentlySendingSignInEmail ? (
            <Button
              variant="contained"
              color="primary"
              disabled={true}
              fullWidth>
              <CircularProgress size={24} />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={validEmail === undefined || signInEmailSent}
              fullWidth
              type="submit">
              {!signInEmailSent
                ? 'Email Sign-in Link'
                : 'Check inbox for sign-in email'}
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

export default SignIn
