import React, { FunctionComponent } from 'react'

import firebase from 'firebase/app'

import { WindowLocation } from '@reach/router'

import DefaultLayout from '../layouts/index'

import FirebaseLoadable from '../components/firebase-loadable'
import SignIn from '../components/sign-in'

type SignInPageProps = {
  location: WindowLocation
}

const SignInPage: FunctionComponent<SignInPageProps> = ({ location }) => {
  let resolver
  let rejecter
  const firebasePromise = new Promise<firebase.FirebaseApp>(
    (resolve, reject) => {
      resolver = resolve
      rejecter = reject
    },
  )
  const [firebaseApp, setFirebaseApp] = React.useState<
    firebase.FirebaseApp | undefined
  >(undefined)

  firebasePromise
    .then((fbApp: firebase.FirebaseApp) => {
      setFirebaseApp(fbApp)
    })
    .catch((reason) => {
      console.error(reason)
    })

  return (
    <DefaultLayout suppressSignOutLink={true} location={location}>
      <FirebaseLoadable resolver={resolver} rejecter={rejecter} />
      {!firebaseApp ? (
        <div>Initializing Firebase...</div>
      ) : (
        <SignIn location={location} firebase={firebaseApp} />
      )}
    </DefaultLayout>
  )
}

export default SignInPage
