import React from 'react'

export { sanitizePath } from '../../../common/utils'

import * as constants from './constants'
import { Option, None } from './types'

export function unpackChangeEventValue(
  callback: (value: string) => void,
): (
  event: React.ChangeEvent<
    HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
  >,
) => void {
  return (event) => {
    if (!event) {
      return
    }
    callback(event.target.value)
  }
}

export function validateEmail(address: string): Option<Error> {
  const [recipient, domain, ...rest] = address.split('@')
  if (recipient === undefined || recipient === '') {
    return Error('Address is missing recipient')
  }
  if (domain === undefined || domain === '') {
    return Error('Address is missing domain')
  }
  if (rest.length > 0) {
    return Error('Address contains more than one @ symbol')
  }
  if (!constants.validEmailRecipientRegex.test(recipient)) {
    return Error('Address recipient is not valid')
  }
  if (!constants.validDomainRegex.test(domain)) {
    return Error('Address domain is not valid')
  }
  return None
}
